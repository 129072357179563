import React, { useEffect } from "react"
import get from "lodash/get"
import Img from "../components/CustomImg"
import { useScrollPercentage } from "react-scroll-percentage"
import Button from "../components/Button"
import InternalLink from "../components/InternalLink"
import { Link } from "gatsby"
import { defaultLang } from "../../prismic-configuration"
import getTranslations from "../translations"

const Hero = props => {
  const { heading, prismicImage, heroCTALink, heroCTAText, lang } = props
  const t = getTranslations(lang)
  const ctaText = get(heroCTAText, "[0].text")
  const ctaMeta = get(heroCTALink, "_meta")

  const [ref, percentage] = useScrollPercentage({
    /* Optional options */
    threshold: 0,
  })

  useEffect(() => {
    if (percentage < 1) {
      document.body.classList.add("invert-header")
    } else {
      document.body.classList.remove("invert-header")
    }

    return () => {
      document.body.classList.remove("invert-header")
    }
  }, [ref])

  return (
    <div className="hero co--lightest" ref={ref}>
      <div className="hero-inner">
        <h1 className="fs--xl lh--s">{heading}</h1>
        <div className="hero-buttons">
          <Link to={`${lang === defaultLang || !lang ? '/stylerad' : lang + '/style-advice'}`}>
            <Button text={t.hero.book_meeting} icon="chevron-right" />
          </Link>
          <Link to={`${lang === defaultLang || !lang ? '/stofproever' : lang + '/fabric-samples'}`}>
            <Button text={t.hero.order_samples} icon="chevron-right" />
          </Link>
          {ctaMeta && (
            <InternalLink title={ctaText} uid={ctaMeta.uid} type={ctaMeta.type} lang={ctaMeta.lang}>
              {ctaText && <Button text={ctaText} icon="chevron-right" />}
            </InternalLink>
          )}
        </div>
      </div>
      <Img title={heading} prismicImage={prismicImage} inView={true} />
    </div>
  )
}

export default Hero
